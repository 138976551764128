@use "uswds-core" as *;

// Map for high contrast mode icons
$-chevron-icon-defaults: (
  "color": currentColor,
  "svg-height": 20,
  "svg-width": 20,
  "height": units(2.5),
);

$-chevron-next: map-merge(
  $-chevron-icon-defaults,
  (
    "name": "navigate_next",
  )
);

$-chevron-expand-more: map-merge(
  $-chevron-icon-defaults,
  (
    "name": "expand_more",
  )
);

// General footer styles
.usa-footer {
  @include border-box-sizing;
  @include typeset($theme-footer-font-family);
  overflow: hidden;

  > .grid-container {
    @include grid-container($theme-footer-max-width);
  }
}

.usa-footer__return-to-top {
  @include u-padding-y(2.5);
  line-height: line-height($theme-footer-font-family, 1);

  a {
    @include typeset-link;
  }
}

.usa-footer__nav {
  @include grid-container($theme-footer-max-width);
  @include u-padding-x(0);
  border-bottom: 1px solid color("base-light");

  @include at-media("mobile-lg") {
    @include add-responsive-site-margins;
    border-bottom: none;
  }

  > ul {
    @include add-list-reset;
  }
}

.usa-footer__primary-section {
  background-color: color("base-lightest");

  > .grid-container {
    @include grid-container($theme-footer-max-width);
  }
}

.usa-footer__primary-container {
  @include grid-container($theme-footer-max-width);

  @include at-media("desktop") {
    @include u-padding-x(4);
  }
}

.usa-footer__primary-content {
  line-height: line-height($theme-footer-font-family, 2);
}

.usa-footer__primary-link {
  @include u-padding-x($theme-site-margins-mobile-width);
  @include u-padding-y(2);
  @include u-text("ink", "bold");
  display: block;

  @include at-media("mobile-lg") {
    @include u-padding-x(0);
  }

  // Disclosure button functionality happens at mobile widths
  &--button {
    @include place-icon($-chevron-expand-more, "before", 0.5);
    width: 100%;
    border: 0;
    cursor: pointer;

    &:not([disabled]):focus {
      // Apply negative focus offset to ensure that entire focus ring is visible
      @include focus-outline(null, null, null, "neg-05");
    }

    // Arrow for collapsible content.
    &::before {
      @include u-square(2.5);
      align-items: center;
      background-size: contain;
      content: "";
      display: inline-flex;
      justify-content: center;
      margin-right: units(0.5);
      margin-left: units(-0.5);

      @media (forced-colors: active) {
        background-color: buttonText !important;
      }
    }

    & + .usa-list--unstyled {
      margin-top: units(1);
      margin-bottom: units(1);
    }

    &[aria-expanded="false"] {
      @include place-icon($-chevron-next, "before", 0.5);

      // Hide submenu when button is not expanded
      & + .usa-list--unstyled {
        display: none;
      }
    }
  }
}

.usa-footer__secondary-link {
  line-height: line-height($theme-footer-font-family, 2);
  margin-left: units(2);
  padding: 0;

  a {
    @include typeset-link;
  }

  & + .usa-footer__secondary-link {
    padding-top: units(2);
  }

  @include at-media("mobile-lg") {
    margin-left: 0;
  }
}

.usa-footer__contact-info {
  line-height: line-height($theme-footer-font-family, 2);

  a {
    @include u-text("ink");
  }

  @include at-media("mobile-lg") {
    @include u-flex("justify-end");
    margin-top: units(1);
  }
}

.usa-footer__primary-content {
  border-top: 1px solid color("base-light");

  @include at-media("mobile-lg") {
    border: none;
  }
}

.usa-sign-up {
  padding-bottom: units(4);
  padding-top: units(3);

  .usa-label,
  .usa-button {
    margin-top: units(1.5);
  }
}

.usa-sign-up__heading {
  @include h3;
  margin: 0;
}

.usa-footer__secondary-section {
  @include u-padding-y(2.5);
  @include set-text-and-bg("base-lighter");

  > .grid-container {
    @include grid-container($theme-footer-max-width);
  }

  a {
    color: color("ink");
  }
}

.usa-footer__logo {
  @include u-margin-y(1);

  @include at-media("mobile-lg") {
    @include u-margin-y(0);
    @include u-flex("align-center");
  }
}

.usa-footer__logo-img {
  max-width: units(10);
}

.usa-footer__logo-heading {
  @include typeset($theme-footer-font-family, $theme-h3-font-size, 1);
  @include u-font-weight("bold");
  @include u-margin-y(1);
}

.usa-footer__contact-links {
  margin-top: units(3);

  @include at-media("mobile-lg") {
    margin-top: 0;
    text-align: right;
  }
}

.usa-footer__contact-heading {
  @include typeset(
    $theme-footer-font-family,
    $theme-h3-font-size,
    $theme-heading-line-height
  );
  @include u-font-weight("bold");
  margin-top: 0;

  @include at-media("mobile-lg") {
    @include u-margin-y(0.5);
  }
}

.usa-footer__social-links {
  line-height: line-height($theme-footer-font-family, 1);
  padding-bottom: units(1);

  a {
    text-decoration: none;
  }

  @include at-media("mobile-lg") {
    @include u-flex("justify-end");
  }
}

.usa-social-link {
  @include u-square($size-touch-target);
  background-color: color("black-transparent-10");
  display: inline-block;
  padding: units(0.5);

  &:hover {
    background-color: color("white");
  }

  @media (forced-colors: active) {
    background-color: lightgrey;
    forced-color-adjust: none;
  }
}

.usa-social-link__icon {
  display: block;
  height: auto;
  width: 100%;
}

.usa-footer__address {
  @include at-media("mobile-lg") {
    @include u-flex("justify-end");
  }
}

// Slim footer styles

.usa-footer--slim {
  .usa-footer__nav {
    @include at-media("desktop") {
      @include u-padding-x(0);
    }
  }

  .usa-footer__address {
    @include u-padding-x($theme-site-margins-mobile-width);
    @include u-padding-y(2);

    @include at-media("mobile-lg") {
      @include u-padding(0);
    }
  }

  .usa-footer__logo {
    @include u-flex("align-center");
  }

  .usa-footer__logo-img {
    max-width: units(6);
  }

  .usa-footer__contact-info {
    display: inline-block;

    @include at-media("mobile-lg") {
      @include u-padding-y(2);
      margin-top: 0;
    }
  }
}

// Big footer styles

.usa-footer--big {
  .usa-footer__nav {
    @include u-margin-x($theme-site-margins-mobile-width * -1);
    @include at-media("mobile-lg") {
      border-bottom: 1px solid color("base-light");
      padding-top: units(4);
    }
    @include at-media("tablet") {
      @include u-margin-x(0);
      @include u-padding-x(0);
      border-bottom: none;
    }
  }

  .usa-footer__primary-link {
    @include h4;
    line-height: line-height("heading", 2);
    margin: 0;

    @include at-media("mobile-lg") {
      @include u-padding-y(0);
      margin-bottom: units(1);

      &:hover {
        cursor: auto;
        text-decoration: none;
      }
    }
  }

  .usa-footer__primary-content--collapsible {
    .usa-footer__primary-link {
      align-items: center;
      display: flex;
      justify-content: flex-start;
    }

    .usa-list--unstyled {
      @include u-padding-x($theme-site-margins-mobile-width);
      padding-bottom: units(2.5);

      @include at-media("mobile-lg") {
        @include u-padding-x(0);
        padding-bottom: units(4);
        padding-top: units(1.5);
      }
    }
  }
}
